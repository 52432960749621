<template>
<div class="modal">
  <h1>Ошибка!</h1>
  <p>Пользователь с таким&nbsp;email уже&nbsp;существует!</p>
  <a :href="staticUrl">Вход в личный кабинет</a>
  <div
    class="cancel"
    @click="closeModal"
  ></div>
</div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'ModalError',
  computed: {
    ...mapGetters([
      'staticUrl'
    ])
  },
  methods: {
    closeModal: function () {
      this.$store.dispatch('setModal')
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.cancel {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
  }

  a {
    margin-top: 20px;
    padding: 10px 15px;
    background: $line-color;
    border-radius: 30px;
    text-decoration: none;
    color: black;
  }
}
</style>
