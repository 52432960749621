<template>
  <div class="answer">
    <template v-if="data.data.img">
      <p>{{ data.data.textTop }}</p>
      <img v-if="data.data.img" :src="require('@/assets/img/' + data.gender + '/step-' + data.step + '/' + data.data.img)" alt="">
      <p>{{ data.data.textBottom }}</p>
    </template>
    <template v-else>
      <h3><span>Ваш логин:</span> {{ email }}</h3>
      <h3><span>Ваш пароль:</span> {{ password }}</h3>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'answer10',
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      email: null,
      password: null
    }
  },
  computed: {
    ...mapGetters([
      'checkEmail',
      'apiUrl',
      'token'
    ])
  },
  created () {
    if (!this.checkEmail && !this.data.data.img) {
      axios
        .patch(this.apiUrl + '/user/email/fast', {}, {
          headers: {
            Authorization: this.token
          }
        })
        .then((response) => {
          this.email = response.data.email
          this.password = response.data.password
          this.$emit('choose', { key: this.data.key, params: this.email })
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.answer {
  width: 100%;
  height: 50%;
  padding: 15px 20px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  transition: .5s;

  @media (max-height: 800px) {
    margin-top: 0;
    padding-bottom: 0;
  }

  @media (max-height: 770px) {
    margin-bottom: -15px;
  }

  @media (max-height: 700px) {
    font-size: .9em;
  }

  @media (max-height: 550px) {
    font-size: .8em;
  }

  @media (max-height: 510px) {
    padding-top: 5px;
  }

  @media (max-height: 500px) {
    font-size: .75em;
  }

  img {
    width: auto;
    height: 100%;
    margin: 0 auto;

    @media (max-height: 680px) {
      width: 80%;
      height: auto;
    }

    @media (max-width: 500px) {
      width: 100%;
      height: auto;
    }
  }

  p {
    color: $line-color-dark;
    margin-bottom: 20px;

    @media (max-height: 680px) {
      padding-left: 5%;
    }

    @media (max-width: 500px) {
      padding-left: 0 !important;
    }

    @media (max-height: 600px) {
      margin-bottom: 5px;
    }
  }

  h3 span {
    font-weight: normal;
  }
}

</style>
