import answer1 from '@/components/answers/Answer1'
import answer2 from '@/components/answers/Answer2'
import answer3 from '@/components/answers/Answer3'
import answer4 from '@/components/answers/Answer4'
import answer5 from '@/components/answers/Answer5'
import answer6 from '@/components/answers/Answer6'
import answer7 from '@/components/answers/Answer7'
import answer8 from '@/components/answers/Answer8'
import answer9 from '@/components/answers/Answer9'
import answer10 from '@/components/answers/Answer10'
import answer11 from '@/components/answers/Answer11'
import answer12 from '@/components/answers/Answer12'

export default { answer1, answer2, answer3, answer4, answer5, answer6, answer7, answer8, answer9, answer10, answer11, answer12 }
