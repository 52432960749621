<template>
  <div>
    <h1>Условия Конфиденциальности CHEATMEAL</h1>
    <h4>ОГРНИП – 320237500021151</h4>
    <h4>ИНН – 237306578484</h4>
    <p>1.     ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
    <p>1.1.     Если в рамках настоящей Оферты Исполнитель получит от Заказчика Персональные данные физических лиц, то
    Исполнитель обязуется обрабатывать Персональные данные строго в соответствии с принципами и правилами обработки
    персональных данных, предусмотренными законодательством РФ. Заказчик передает Исполнителю персональные данные с
    целью оказания Услуг, указанных в настоящей оферте и поручает Исполнителю их обработку, совершаемую с использованием
    средств автоматизации или без использования таких средств, включая сбор, запись, систематизацию, накопление, хранение,
    уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование,
    удаление, уничтожение персональных данных.</p>
    <p>1.2.     Исполнитель обеспечивает соблюдение всех требований законодательства и иных нормативно-правовых актов РФ в отношении обработки
    и передачи персональных данных физических лиц, полученных от Заказчика.</p>
    <p>1.3.     Исполнитель использует персональные данные физических лиц, полученные от Заказчика только для оказания Услуг в рамках настоящей Оферты.</p>
    <p>1.4.     Заказчик обязан выполнить все требования законодательства и иных нормативно-правовых актов в отношении обработки и передачи
    Исполнителю персональных данных физических лиц, которые передаются Исполнителю. Все персональные данные, полученные Исполнителем от
    Заказчика, подразумеваются полученными наш законном основании с соблюдением положений законодательства о персональных данных*
    Заказчик признает, что нарушение им порядка обращения персональных данных не налагает на Исполнителя какой-либо ответственности
    и лишает права Заказчика предъявлять претензии к Исполнителю в отношении нарушения режима
    персональных данных, если только такое нарушение не было виновно совершенно Исполнителем при условии соблюдения
    Заказчиком всех требований действующего законодательства об обращении персональных данных.</p>

    <p>Реквизиты и контактная информация Исполнителя:</p>
    <p>ООО "СП СОЛЮШЕН",125039, г. Москва, ПРЕСНЕНСКАЯ НАБЕРЕЖНАЯ, д. 10, стр. 2, ПОМЕЩ. 97, КОМ. 2, ОФ. 254</p>
    <p>Индекс 353217</p>
    <p>ИНН – 237306578484</p>

    <p>Служба поддержки: admin@cheatmeal.pro</p>
    <p>СОГЛАСИЕ на получение рассылки и рекламных материалов</p>

    <p>Я, регистрируясь и/или вводя свои данные на сайте <a href="https://cheatmeal.pro/ru/"> https://cheatmeal.pro/ru/ </a>, его сервисах или его поддоменах, даю своё согласие
    на получение рассылки материалов рекламного и/или информационного характера посредством SMS-сервисов, электронной почты, мессенджеров,
    с помощью телекоммуникационных каналов связи от ООО "СП СОЛЮШЕН"</p>

    <p>Я регистрируюсь и/или ввожу свои данные в <a href="https://cheatmeal.pro/ru/">https://cheatmeal.pro/ru/</a> , а также на его сервисах или его поддоменах (в том числе с целью направления заявки на получении услуг).
    Даю свое согласие на получение рассылки, в том числе рекламной, содержащей информацию, в том числе, но не ограничиваясь, о
    товарах и услугах, наличие специальных предложений, акций в отношении них, условиях, связанных с приобретением и
    использованием указанных товаров и услуг, о проведении мероприятий, презентаций, предложение партнеров, а также
    рассылок подготовленных в качестве личных рекомендаций для меня с учетом анализа покупательского поведения
    посредством рассылки (направление сообщений на электронную почту или с помощью средств мест массовой информации).</p>

    <p>Давая такое согласие, я подтверждаю, что действую по своей воле и в своем интересе, а также обязуюсь предоставить достоверные данные.</p>

    <p>Я подтверждаю, что владею информацией о том, что в любой момент в течение всего срока действия настоящего согласия,
    я вправе отозвать согласие данного соглашения, и отписаться от получения рассылок путем перехода по соответствующей ссылке, существующей в любом письме.</p>

    <p>Также я информирован (-а), что при возникновении вопросов относительно отказа, в том числе от смс рассылок,
    я могу обратиться за помощью, отправив письмо на следующий адрес электронной почты: admin@cheatmeal.pro</p>

    <p>Срок действия данного соглашения 5 лет. Соглашение считается автоматически продленным на следующий период,
    если участники не выразили письменное сообщение об изменении его условий или о расторжении в течение 10 дней со дня его окончания срока</p>

    <p>Реквизиты и контактная информация Исполнителя:</p>
    <p>ООО "СП СОЛЮШЕН", г. Москва, ПРЕСНЕНСКАЯ НАБЕРЕЖНАЯ, д. 10, стр. 2, ПОМЕЩ. 97, КОМ. 2, ОФ. 254</p>
    <p>Индекс 125039</p>
    <p>ИНН – 9703059075</p>

    <p>Служба поддержки: admin@cheatmeal.pro</p>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Policies',
  computed: {
    ...mapGetters([
      'config'
    ])
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/main.scss";

div {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

span {
  width: 100%;
  text-align: right;
  display: block;
  margin-bottom: 50px;
  font-style: italic;
}

div{
  padding: 32px 0 32px 0;
}

h1, h2 {
  margin: 30px 0 30px 0;
  width: 100%;
  text-align: center;
}

h3 {
  margin-bottom: 20px;
}

h5 {
  margin-bottom: 20px;
}

p {
  margin-bottom: 20px;
  line-height: 1.8em;
}

.requisites {
  margin-top: 20px;
  margin-bottom: 150px;

  p {
    margin-bottom: 5px;
  }
}

.conditions {
  margin-bottom: 20px;

  h5 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 5px;
  }
}

.tarifs {
  padding-bottom: 50px;
}

</style>
