<template>
  <div v-if="loading" id="loading"></div>
  <prelend v-else-if="!gender" />
  <step v-else @next="next"/>
</template>

<script>

import prelend from '@/components/Prelend'
import Step from '@/components/Step'
import router from '@/router'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'

export default {
  components: { Step, prelend },
  data: function () {
    return {
      checked: [],
      loading: true
    }
  },
  created () {
    console.log(this.steps)
    axios
      .get(this.apiUrl + '/user/questions', {
        headers: {
          Authorization: this.token
        }
      })
      .then((response) => {
        response = response.data
        window.response = response
        console.log(response)
        if (
          (response.email !== null) &&
          (response.gender !== null) &&
          (Object.keys(response.questions).length > 0) &&
          ('meat' in response.questions) &&
          (response.questions.meat !== null)
        ) {
          this.RESULT({ ...response.questions, email: response.email })
          this.setGender(response.gender)
          router.push({ name: 'Result' })
        } else {
          this.loading = false
        }
      })
      .catch(() => { this.loading = false })
  },
  computed: {
    ...mapGetters([
      'config',
      'gender',
      'result',
      'step',
      'apiUrl',
      'token',
      'steps'
    ]),
    getStepTitle: function () {
      return this.config[this.gender].steps[this.step].value || 'email'
    },
    stepsLength () {
      return this.steps.length
    }
  },
  methods: {
    ...mapMutations([
      'RESULT',
      'GENDER'
    ]),
    ...mapActions([
      'nextStep',
      'setGender'
    ]),
    next: function (value) {
      if (Array.isArray(value)) {
        const tmp = []
        value.forEach((item) => {
          tmp.push(this.getStepAnswer(item))
        })
        value = tmp
      } else {
        value = this.getStepAnswer(value)
      }
      this.checked[this.getStepTitle] = value
      this.nextStep({
        key: this.getStepTitle,
        value: value
      })
    },
    getStepAnswer: function (idx) {
      const answer = this.config[this.gender].steps[this.step].answers[Number(idx)]
      if (typeof answer !== 'undefined') {
        return answer.value || 'email'
      } else return idx
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";
</style>
