<template>
<div class="modal">
  <h1>Знаете ли вы?</h1>
  <p>У <b>65 %</b> пользователей, которые начали свою <b>программу похудения с CheatMeal</b>, произошли изменения <b>в первый месяц!</b></p>
  <img src="@/assets/img/pay-decline.png" alt="График">
  <div class="grey">
    <p>Мы желаем и Вам в этом преуспеть, поэтому предлагаем Вам <b style="cursor:pointer;" @click="closeModal">персональную скидку</b> на индивидуальный план питания и программу тренировок!</p>
  </div>
  <div
    class="cancel"
    @click="closeModal"
  ></div>
</div>
</template>

<script>

export default {
  name: 'ModalPayDecline',
  methods: {
    closeModal: function () {
      this.$store.dispatch('setModal')
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

img {
  width: 100%;
  height: auto;
}

.cancel {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
