<template>
<div>
  <h1 class="title">{{ config?.tarifs.title }}</h1>
  <p v-for="(item, key) in config?.tarifs.points" :key="key">{{ item }}</p>
</div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Tarif',
  computed: {
    ...mapGetters([
      'config'
    ])
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/main.scss";

div {
  width: 100%;
  max-width: 1280px;
  margin: 32px auto 0 auto;
}

h1 {
  margin-bottom: 30px;
}

h3 {
  margin-bottom: 15px;
}

p {
  margin-bottom: 10px;

  &:last-child {
    padding-bottom: 40px;
  }
}

</style>
