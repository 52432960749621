<template>
  <div class="answer">
    <div class="answer__form">
      <h3 class="answer__form-title">Введите ваш Email, чтобы получить
        план питания и программу тренировок <span class="answer__form-span">для снижения веса</span>
      </h3>
      <p class="answer__form-subtitle">Мы уважаем вашу конфиденциальность и очень серьезно относимся к ее защите —
        никакого спама. </p>
      <fieldset class="answer__form-fieldset">
        <input class="answer__form-input" type="text" id="email" v-model="value" placeholder=" " />
        <label class="answer__form-label" for="email">
          E-mail
        </label>
      </fieldset>
      <label class="answer__checkbox-label" for="accept">
        <!-- <input class="answer__checkbox-input" type="checkbox" v-model="check" id="accept"/> -->
        <p class="answer__checkbox-text">Продолжая, вы соглашаетесь с подпиской на сервис, пользовательским соглашением,
          офертой, правилами сервиса, рекуррентными платежами за продление подписки
        </p>
      </label>
    </div>
    <p class="answer__subtitle"> *Подробнее о тарифах сервиса
      ИНН 9703059075, ОГРНИП 1217700551529
     ООО "СП СОЛЮШЕН"
      <a class="answer__link" href="terms" target="_blank">
        Оферта и Правила сервиса
      </a>
      <a class="answer__link" href="policies" target="_blank">
        Политика конфиденциальности
      </a>
      <a class="answer__link" href="tarifs" target="_blank">
        Тарифы
      </a>
      <a class="answer__link" href="https://cheatmeal.pro/unsubscribe/" target="_blank">
        Отмена подписки
      </a>
      help@cheatmeal.pro
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'answer9',
  props: {
    data: {
      type: Object
    }
  },
  data: function () {
    return {
      value: '',
      check: true
    }
  },
  methods: {
    ...mapGetters([
      'validError',
      'checkEmail'
    ])
  },
  watch: {
    value: {
      handler: function () {
        this.$emit('choose', { key: this.data.key, params: this.check ? this.value : '' })
      },
      immediate: true
    },
    check: {
      handler: function () {
        this.$emit('choose', { key: this.data.key, params: this.check ? this.value : '' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.answer {

  &__form {
    width: 100%;
    max-width: 380px;
    margin: 80px 0;

    &-title {
      margin: 0 0 32px 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 1px;
      color: #323232;
      margin-bottom: 35px;
    }

    &-subtitle {
      margin: 0 0 32px 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 1px;
      color: #323232;
    }

    &-fieldset {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 0;
      border: none;
      margin: 0 0 15px 0;
    }

    &-error {
      margin: 6px 0 0 0;
      font-size: 12px;
      color: red;
      line-height: 1;
    }

    &-input {
      width: 100%;
      height: 60px;
      border-radius: 15px;
      border: 1px solid #000;
      outline: none;
      padding: 0 0 0 12px;
      caret-color: #42b983;

      &:focus~.answer__form-label {
        top: -8px;
        color: #42b983;
        display: block;
        background-color: #fff;
        padding: 2px;
        font-size: 12px;
        transition: all .1s ease-in-out;
      }

      &:not(:placeholder-shown)~.answer__form-label {
        top: -8px;
        color: #42b983;
        display: block;
        background-color: #fff;
        padding: 2px;
        font-size: 12px;
        transition: all .1s ease-in-out;
      }

      &:-webkit-autofill~.answer__form-label {
        top: -8px;
        color: #42b983;
        display: block;
        background-color: #fff;
        padding: 2px;
        font-size: 12px;
        transition: all .1s ease-in-out;
      }
    }

    &-label {
      font-size: 16px;
      line-height: 1;
      color: rgba(0, 0, 0, .6);
      position: absolute;
      top: calc(50% - 8px);
      left: 12px;
    }

    &-span {
      color: #42b983;
    }
  }

  &__checkbox {
    position: relative;

    &-label {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 8px 0 32px 0;
      cursor: pointer;
      z-index: 6;
    }

    &-input {
      min-width: 16px;
      min-height: 16px;
      background-color: #ec445a;
      margin: 0 8px 0 0;
      cursor: pointer;
    }

    &-text {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1px;
      color: #000;
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    letter-spacing: 1px;
    color: #000;
    display: block;
    max-width: 360px;
    margin: 0 0 64px 0;
  }

  &__link {
    color: #000;
    font-weight: 500;
    text-decoration: none;

    &::after {
      content: '|';
    }

    &:first-child,
    &:last-child {

      &::after {
        content: '';
      }
    }
  }
}
</style>
