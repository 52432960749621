<template>
<div :class="['modal', gender ?? 'female']">
  <h1>Специальное предложение<br>только для новых пользователей</h1>
  <span class="description">Возможность получить доступ к тарифу "Премиум" по суперцене</span>
  <ul>
    <li>Индивидуальный план питания</li>
    <li>Индивидуальный план тренировок</li>
    <li>Полезные статьи от профессиональных диетологов</li>
    <li>Круглосуточная поддержка</li>
  </ul>
  <h1>Тариф <span class="cross-out">849 руб</span> 1 руб</h1>
  <span class="description no-gender">Доступ к тарифу "Премиум" на неделю за 1Р, далее 849Р списываются автоматически каждые 2 дня</span>
  <div class="pay">
    <a id="continue" :href="disabled" :class="{ disabled: !(checked1 && checked2) }" :disabled="!checked">Продолжить</a>
  </div>
  <div class="description__container">
    <input type="checkbox" class="description__checkbox" id="terms" v-model="checked1"/>
    <span class="description no-gender">Нажимая на кнопку, Вы даете свое согласие на автоматические списания 849Р раз в 2 дня, соглашаетесь с условиями
      <a href="terms" target="_blank"> публичной оферты </a> и действующими
      <a href="tarifs" target="_blank"> тарифами сервиса.</a>
    </span>
  </div>
  <div class="description__container">
    <input type="checkbox" class="description__checkbox" id="policies" v-model="checked2"/>
    <span class="description no-gender">Нажимая на кнопку, Вы даете свое согласие на обработку<a href="policies" target="_blank"> персональных данных </a>
    и на получение рекламных материалов, осознаете возмездный характер оказываемых услуг
    </span>
  </div>
  <div
    class="cancel"
    @click="closeModal"
  ></div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'ModalPay',
  data () {
    return {
      checked1: false,
      checked2: false,
      href: null,
      loading: true
    }
  },
  created () {
    axios
      .get(this.apiUrl + '/pay?value=1', {
        params: {
          lk: 'https://lk.cheatmeal.org/auth/'
        },
        headers: {
          Authorization: this.token
        }
      })
      .then((response) => {
        if (response.data.success) {
          this.href = response.data.url
        } else {
          this.href = this.staticUrl + '/?token=' + this.token
        }
        // this.href = response.data.confirmation.confirmation_url
        this.loading = false
      })
      .catch(() => {
        this.href = this.staticUrl + '/?token=' + this.token
        this.loading = false
      })
  },
  computed: {
    ...mapGetters([
      'cost',
      'gender',
      'config',
      'result',
      'staticUrl',
      'apiUrl',
      'token'
    ]),
    disabled () {
      if (this.loading || !this.checked1 || !this.checked2) return '#'
      else return this.href
    }
  },
  methods: {
    closeModal: function () {
      this.$store.dispatch('setModal')
    },
    goLk: function () {
      if (this.href) {
        window.location.href = this.href
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.modal {

  h1 {
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    text-transform: uppercase;

    @media (max-height: 560px), (max-width: 400px) {
      font-size: 17px;
      line-height: 22px;
    }

    @media (max-width: 400px) AND (max-height: 560px) {
      font-size: 16px;
      line-height: 20px;
    }

    &:last-of-type {
      width: 80%;
      height: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
      padding: 10px;

      @media (max-width: 400px) AND (max-height: 510px) {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .description {
    font-size: 13px;
    text-align: start;
    display: block;
    margin: 0 0 8px 8px;

    &__container{
      padding: 0 8px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    &__checkbox{
      margin: 2px 0 0 0 ;
      min-width: 14px;
      min-height: 14px
    }

    @media (max-height: 630px) {
      margin-bottom: 10px;
    }

    @media (max-width: 400px) {

      @media (max-height: 690px) {
        margin-bottom: 10px;
      }

      @media (max-height: 580px) {
        margin-bottom: 5px;
      }

      @media (max-height: 560px) {
        margin-bottom: 0;
      }
    }

    &.no-gender {
      color: black !important;
      font-size: 10px;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 30px;

    li {
      font-size: 16px;
      position: relative;
      margin-bottom: 4px;

      @media (max-height: 580px) {
        font-size: 14px;
      }

      @media (max-height: 540px) {
        font-size: 13px;
      }

      @media (max-width: 400px) AND (max-height: 630px) {
        font-size: 13px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -15px;
        border-style: solid;
        border-width: 3px;
        border-right: none;
        border-top: none;
        width: 10px;
        height: 4px;
        transform: rotate(-45deg) translateY(-100%);
      }
    }
  }

  .pay {
    border-radius: 10px;
    margin: 8px 0 8px 0;

    @media (max-height: 650px) {
      padding: 15px;
      margin-bottom: 5px;
    }

    @media (max-width: 400px) {
      @media (max-height: 710px) {
        margin-bottom: 5px;
      }

      @media (max-height: 550px) {
        padding: 3px;
      }

      @media (max-height: 500px) {
        margin-bottom: 0;
      }
    }

    #continue {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 10px;
      color: white;
      border: none;
      box-shadow: none;
      font-size: 25px;
      padding: 10px 20px;
      text-decoration: none;

      @media (max-height: 500px) {
        margin-bottom: 3px;
      }
    }

    .checkbox {
      label {
        display: flex;
        align-items: center;
        color: black;
        font-weight: lighter;
        font-size: 14px;
        position: relative;
        padding-left: 10px;
        margin-left: 20px;

        @media (max-width: 340px) {
          font-size: 13px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: -10px;
          width: 14px;
          height: 14px;
          border: 1px solid black;
          margin-right: .5em;
          background: white;
          transform: translateY(-50%);
        }
      }

      input {
        display: none;

        &:checked + label:after {
          content: '';
          position: absolute;
          top: 50%;
          left: -3px;
          border-style: solid;
          border-width: 2px;
          border-right: none;
          border-top: none;
          width: 8px;
          height: 3px;
          transform: rotate(-45deg) translateY(-100%);
        }
      }
    }
  }

  .cross-out {
    position: relative;
    background: white;
    padding: 7px;
    border-radius: 7px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -2px;
      width: calc(100% + 4px);
      height: 2px;
      transform: rotate(-12deg);
    }
  }

  &.male {
    .description {
      color: $color-male-primary;
    }

    ul li:before {
      border-color: $color-male-primary;
    }

    .cross-out:before {
      background: $color-male-primary;
    }

    .pay {
      .disabled{
          cursor: default !important;
          background: $color-male-tertiary !important;
          color: $line-color;
      }
      #continue {
        background: $color-male-primary;

        &:disabled {
          background: $color-male-tertiary;
          color: $line-color;
        }
      }
    }

    h1:last-of-type {
      background: $color-male-tertiary;
    }
  }

  &.female {
    .description {
      color: $color-female-primary;
    }

    ul li:before {
      border-color: $color-female-primary;
    }

    .cross-out:before {
      background: $color-female-primary;
    }

    .pay {
      .disabled{
        background: $color-female-tertiary !important;
        color: $line-color;
        cursor: default !important;
      }
      #continue {
        background: $color-female-primary;

        &:disabled {
          background: $color-female-tertiary;
          color: $line-color;
        }
      }
    }

    h1:last-of-type {
      background: transparentize($color-female-tertiary, .7);
    }
  }
}

.cancel {
  position: absolute;
  top: 10px;
  right: 10px;
}

#continue {
  width: 70%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid $line-color-dark;
  outline: none;
  margin-bottom: 5px;
  cursor: pointer;
  color: black;
  box-shadow: 0 0 10px -5px black;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
