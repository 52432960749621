<template>
  <div class="answer">
    <label class="answer__label"><input class="answer__input" type="number" :min="data.data.min" :max="data.data.max" v-model="value" @keypress="filterNumber"/>
      <p class="answer__text">{{ data.data.name }}</p>
    </label>
  </div>
</template>

<script>
export default {
  name: 'answer5',
  props: {
    data: {
      type: Object
    }
  },
  data: function () {
    return {
      value: ''
    }
  },
  methods: {
    filterNumber: function ($event) {
      const keyCode = $event.keyCode
      if (keyCode < 48 || keyCode > 57) { // 46 is dot
        $event.preventDefault()
      }
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.$emit('choose', { key: this.data.key, params: Number(val) })
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.answer {
  width: 100%;

  &__input{
    width: 100%;
    height: 52px;
    border: 2px solid #dde5ed;
    padding: 12px 9px 12px 12px;
    border-radius: 12px;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;

    &:focus{
      border-color: rgb(104, 223, 250);
    }
  }

  &__label{
    width: 100%;
    position: relative;
  }

  &__text{
    position: absolute;
    right: 32px;
    top: 0;
  }
}

</style>
