<template>
  <div class="success">
    <header class="success__header">
      <div class="success__header-logo">
        <img :src="require('@/assets/img/logo.png')"/>
      </div>
      <div class="success__timer">
        <p class="success__timer-text">Справедливая цена в течение:</p>
        <p class="success__timer-counter">{{ Math.floor(timer/60) + ' : ' + (timer%60 >= 10 ? '' : '0') + timer%60 }}</p>
      </div>
    </header>
    <div class="success__container">
      <h2 class="success__title">
        <span class="success__title-span"> Получите план по воспитанию здоровых привычек</span>
        и похудению по справедливой цене!
      </h2>
      <section class="buy" id="get">
        <div class="buy__left">
          <div class="buy__subtitle-container">
            <p class="buy__subtitle">Получите план здорового диетического питания,
              эффективных тренировок и психологических установок для похудения
            </p>
          </div>
          <div class="get buy__get">
            <h3 class="get__title">Приобретите по справедливой цене</h3>
            <p class="get__percent">{{pircent + '%'}}</p>
            <div class="get__price">
              <p class="get__price-old">2485 руб. </p>
              <p class="get__price-new">> {{price}} руб. до {{currentMonth}} {{ currentDate?.getFullYear() }}</p>
            </div>
            <a class="get__button" :href="disabled1" :class="{disabled: !check1}">Получить персональный план</a>
            <label class="get__label" for="accept">
              <input class="get__input" type="checkbox" id="accept" v-model="check1" />
              <p class="get__text">Принимаю пользовательское соглашение, соглашаюсь с офертой, правилами сервиса, рекурентными
                платежами в размере 890 руб. и другими тарифами сервиса*
              </p>
            </label>
          </div>
        </div>
        <img class="buy__image" :src="require('@/assets/img/success/buy-get.png')" alt="Фотография еды" />
      </section>
      <section class="can">
        <div class="can__graph">
          <h3 class="can__graph-title">У Вас точно получится похудеть!</h3>
          <div class="can__graph-container">
            <div class="can__graph-image-container">
              <ul class="can__graph-counter">
                <li class="can__graph-count" v-for="weight in weights" :key="weight">{{weight}}</li>
              </ul>
              <img class="can__graph-image" :src="require('@/assets/img/success/' + (currentWeight[2] <= result.weight ? 'graph2.png' : 'graph3.png'))" />
            </div>
            <div class="can__graph-text">
              <p class="can__graph-subtitle">Сейчас {{currentMonth}} {{ currentDate?.getFullYear() }}</p>
              <p class="can__graph-subtitle">Прогноз {{nextMonth}} {{ date?.getFullYear() }}</p>
            </div>
          </div>
        </div>
        <div class="can__areas">
          <h3 class="can__areas-title">Ваша программа рассчитана на преображение в этих зонах: </h3>
            <div class="can__areas-line"></div>
            <img class="can__areas-svg" :src="require('@/assets/img/success/areas.svg')" alt="Зоны" />
            <p class="can__areas-subtitle">
              <span>Висцеральный жир</span> — это оболочка ваших органов, увеличивающая
              объем тела. Этот тип жира наиболее опасен, поскольку влияет
              не только на вашу фигуру, но и на ваше здоровье и гормональный фон.
            </p>
            <p class="can__areas-subtitle">Мы уже составили индивидуальный план питания для
              вашего типа телосложения, который будет способствовать потере подкожного и висцерального жира.</p>
          </div>
      </section>
      <section class="slider">
        <h3 class="slider__title">Они уже похудели с Cheat Meal:</h3>
        <div class="slider__container">
          <button class="slider__button slider__button_left" v-on:click="counter > 0 ? counter-- : counter"></button>
          <div class="slider__area" >
            <div class="slider__images" v-bind:style="{marginLeft: -((width < 900 ? 212 : 234) * counter) + 'px'}">
              <img class="slider__image" :src="require('@/assets/img/success/slider/1.jpg')" />
              <img class="slider__image" :src="require('@/assets/img/success/slider/2.jpg')" />
              <img class="slider__image" :src="require('@/assets/img/success/slider/3.jpg')" />
              <img class="slider__image" :src="require('@/assets/img/success/slider/4.jpg')" />
              <img class="slider__image" :src="require('@/assets/img/success/slider/5.jpg')" />
              <img class="slider__image" :src="require('@/assets/img/success/slider/6.jpg')" />
              <img class="slider__image" :src="require('@/assets/img/success/slider/7.jpg')" />
              <img class="slider__image" :src="require('@/assets/img/success/slider/8.jpg')" />
              <img class="slider__image" :src="require('@/assets/img/success/slider/9.jpg')" />
            </div>
          </div>
          <button class="slider__button slider__button_right" v-on:click="counter < 6 ? counter++ : counter"></button>
        </div>
        <div class="slider__control">
          <button :class="['slider__control-button', counter === 0 ? 'slider__control-button_active' : '']" type="button" v-on:click="counter = 0"/>
          <button :class="['slider__control-button', counter === 1 ? 'slider__control-button_active' : '']" type="button" v-on:click="counter = 1" />
          <button :class="['slider__control-button', counter === 2 ? 'slider__control-button_active' : '']" type="button" v-on:click="counter = 2" />
          <button :class="['slider__control-button', counter === 3 ? 'slider__control-button_active' : '']" type="button" v-on:click="counter = 3" />
          <button :class="['slider__control-button', counter === 4 ? 'slider__control-button_active' : '']" type="button" v-on:click="counter = 4" />
          <button :class="['slider__control-button', counter === 5 ? 'slider__control-button_active' : '']" type="button" v-on:click="counter = 5" />
          <button :class="['slider__control-button', counter === 6 ? 'slider__control-button_active' : '']" type="button" v-on:click="counter = 6" />
        </div>
      </section>
      <div class="get">
        <h3 class="get__title">Приобретите по справедливой цене</h3>
        <p class="get__percent">{{pircent + '%'}}</p>
        <div class="get__price">
          <p class="get__price-old">890 руб.</p>
          <p class="get__price-new">> {{price}} руб. до {{currentMonth}} {{ currentDate?.getFullYear() }}</p>
        </div>
        <a id="continue" class="get__button" :href="disabled2" :class="{disabled: !check2}">Получить персональный план</a>
        <label class="get__label" for="accept2">
          <input class="get__input" type="checkbox" id="accept2" v-model="check2"/>
          <p class="get__text">Принимаю пользовательское соглашение, соглашаюсь с офертой, правилами сервиса, рекурентными
            платежами в размере 890 руб. и другими тарифами сервиса*
          </p>
        </label>
      </div>
      <section class="questions">
        <div class="questions__item">
          <div class="questions__info">
            <h4 class="questions__title">Что вы получите?</h4>
            <p class="questions__subtitle">Персональный план питания. Этот план — ключ к ускорению
              вашего метаболического процесса. Все рецепты разработаны профессиональными диетологами.
              Каждый рецепт содержит перечень ингредиентов, пошаговую инструкцию по приготовлению и
              информацию об энергетической ценности. Не нужно подсчитывать калории, мы сделали это за вас.
            </p>
          </div>
          <div class="questions__img-container">
            <img class="questions__img" :src="require('@/assets/img/success/questions/1.png')" alt="План питания"/>
          </div>
        </div>
        <div class="questions__item">
          <div class="questions__img-container">
            <img class="questions__img" :src="require('@/assets/img/success/questions/2.png')" alt="Тренировки и упражнения"/>
          </div>
          <div class="questions__info">
            <h4 class="questions__title">Упражнения для ускорения метаболизма </h4>
            <p class="questions__subtitle">Этот комплекс упражнений не займет много времени, но поможет
              значительно улучшить состояние здоровья, качество сна и стрессоустойчивость.
              Избавляйтесь от лишних килограммов с помощью нашего плана питания!
            </p>
          </div>
        </div>
        <div class="questions__item">
          <div class="questions__info">
            <h4 class="questions__title">Онлайн поддержка</h4>
            <p class="questions__subtitle">Мы на связи 24/7 и готовы ответить на любые вопросы и помочь решить любые проблемы.</p>
            <p class="questions__subtitle">Наши специалисты отдела поддержки передадут ваш запрос профессиональному диетологу и помогут разобратьсяs
              со всеми вопросами, которые у вас могут возникнуть.
            </p>
          </div>
          <div class="questions__img-container">
            <img class="questions__img" :src="require('@/assets/img/success/questions/3.png')" alt="Поддержка кураторов"/>
          </div>
        </div>
      </section>
      <section class="recipes">
        <div class="recipes__item">
          <h4 class="recipes__title">Множество вкусных рецептов, которые помогут вам максимально быстро похудеть.</h4>
          <div class="recipes__line"></div>
          <img class="recipes__img" :src="require('@/assets/img/success/recipes/1.jpg')" alt="Рецепты" />
          <p class="recipes__subtitle">Все рецепты разработаны профессиональными диетологами. Блюда не включают в себя дорогие ингредиенты.</p>
        </div>
        <div class="recipes__item recipes__item_background">
          <h4 class="recipes__title">Эффективные тренировки для наиболее распространенных проблемных зон: руки, ноги, ягодицы и живот. </h4>
          <div class="recipes__line"></div>
          <img class="recipes__img" :src="require('@/assets/img/success/recipes/2.jpg')" alt="Упражнения" />
          <p class="recipes__subtitle">Все упражнения составлены сертифицированными тренерами.</p>
        </div>
      </section>
      <section class="security">
        <div class="security__container">
          <h3 class="security__title">Наша ГАРАНТИЯ ВОЗВРАТА ДЕНЕГ</h3>
          <p class="security__subtitle">Мы не сомневаемся, что наша программа будет для вас эффективной,
            и вы получите видимые результаты через 4 недели! В противном случае мы готовы вернуть
            Ваши деньги обратно, если вы сможете продемонстрировать, что четко следовали нашему плану.
          </p>
          <p class="security__subtitle">Узнайте больше о действующих ограничениях в нашей политике возврата денег.</p>
        </div>
      </section>
      <section class="info">
        <div class="info__column">
          <h3 class="info__title">У пациентов с лишним весом гораздо выше риск негативного течения новой коронавирусной инфекции.</h3>
          <h4 class="info__title info__title_color_green">Избыточный вес повышает и вероятность летального исхода.</h4>
          <p class="info__subtitle">Об этом сообщила, доктор медицинских наук, Института химической биологии и фундаментальной медицины.</p>
          <ul class="info__list">
            <li class="info__list-item">
              <img class="info__list-img" :src="require('@/assets/img/success/icons/list-item.svg')" alt="Значек списка"/>
              <p class="info__list-text">Профессионально подобранный рацион</p>
            </li>
            <li class="info__list-item">
              <img class="info__list-img" :src="require('@/assets/img/success/icons/list-item.svg')" alt="Значек списка"/>
              <p class="info__list-text">Легкие рецепты вкусных и полезных блюд</p>
            </li>
            <li class="info__list-item">
              <img class="info__list-img" :src="require('@/assets/img/success/icons/list-item.svg')" alt="Значек списка"/>
              <p class="info__list-text">Эффективные тренировки для сжигания жира</p>
            </li>
            <li class="info__list-item">
              <img class="info__list-img" :src="require('@/assets/img/success/icons/list-item.svg')" alt="Значек списка"/>
              <p class="info__list-text">Рецепты составлены из простых и недорогих ингредиентов</p>
            </li>
          </ul>
        </div>
        <div class="info__column">
          <p class="info__text">Продолжая, я соглашаюсь c Условиями использования, Политикой приватности, Политикой возврата, Условиями подписки, Политикой cookies</p>
          <p class="info__text">Подписка Cheat Meal автоматически продлевается в конце пробного периода, если вы не отмените ее по крайней мере за 24 часа до окончания оплаченного периода.</p>
          <p class="info__text">Если вы не знаете, как отменить подписку, ознакомьтесь с нашими Условиями подписки.</p>
          <p class="info__text">Ваша пробная версия продлится 3 дня. Вы можете отменить подписку в любое время, и с вас не будет взиматься полная сумма программы.</p>
          <p class="info__text">Через 3 дня после оформления пробной подписки с вас будет списан платеж в размере 890 руб. за 7 дней использования (все подписки смотрите в оферте).</p>
          <p class="info__text">Cheat Meal будет автоматически снимать с вашей карты 890 руб. каждые последующие 7 дней, чтобы вы не потеряли доступ к своей учетной записи.
            Никаких возмещений и возвратов не предусмотрено, кроме случаев, если вы не получили результат при полном соблюдении политики возврата денег.
          </p>
          <p class="info__text">Чтобы отменить подписку, просто напишите на электронную почту: help@cheatmeal.pro</p>
        </div>
      </section>
      <section class="payments">
        <div class="payments__container">
          <h4 class="payments__title">Платеж защищен:</h4>
          <ul class="payments__list">
            <li class="payments__list-item">
              <img class="payments__list-img" :src="require('@/assets/img/success/payments/1.jpg')" alt="https"/>
            </li>
            <li class="payments__list-item">
              <img class="payments__list-img" :src="require('@/assets/img/success/payments/2.jpg')" alt="ssl"/>
            </li>
            <li class="payments__list-item">
              <img class="payments__list-img" :src="require('@/assets/img/success/payments/3.jpg')" alt="3d secure"/>
            </li>
            <li class="payments__list-item">
              <img class="payments__list-img" :src="require('@/assets/img/success/payments/4.jpg')" alt="pci"/>
            </li>
          </ul>
          <h4 class="payments__title">Принимаем к оплате:</h4>
          <ul class="payments__list">
            <li class="payments__list-item">
              <img class="payments__list-img" :src="require('@/assets/img/success/payments/5.jpg')" alt="visa"/>
            </li>
            <li class="payments__list-item">
              <img class="payments__list-img" :src="require('@/assets/img/success/payments/6.jpg')" alt="mc"/>
            </li>
            <li class="payments__list-item">
              <img class="payments__list-img" :src="require('@/assets/img/success/payments/7.jpg')" alt="mir"/>
            </li>
          </ul>
        </div>
      </section>
      <a class="success__button" :href="href">Получить мой план</a>
    </div>
    <footer class="success__footer">
      <div class="success__footer-container">
        <ul class="success__footer-list">
          <li class="success__footer-item">
            <a class="success__footer-link" href="terms" target="_blank">Оферта и Правила сервиса</a>
          </li>
          <li class="success__footer-item">
            <a class="success__footer-link" href="policies" target="_blank">Политика конфиденциальности</a>
          </li>
          <li class="success__footer-item">
            <a class="success__footer-link" href="tarifs" target="_blank">Тарифы</a>
          </li>
          <li class="success__footer-item">
            <a class="success__footer-link" href="https://cheatmeal.pro/unsubscribe/" target="_blank">Отмена подписки</a>
          </li>
          <li class="success__footer-item">
            <p class="success__footer-text">© Cheat Meal</p>
          </li>
          <li class="success__footer-item">
            <p class="success__footer-text">Используя данный сайт, Вы даете согласие на использование файлов cookie, которые помогают сделать сайт удобнее для Вас.</p>
          </li>
          <li class="success__footer-item">
            <p class="success__footer-text">ООО "СП СОЛЮШЕН"</p>
          </li>
          <li class="success__footer-item">
            <p class="success__footer-text">ИНН 9703059075</p>
          </li>
          <li class="success__footer-item">
            <p class="success__footer-text">ОРГНИП 1217700551529</p>
          </li>
          <li class="success__footer-item">
            <p class="success__footer-text">help@cheatmeal.pro</p>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'success',
  data: function () {
    return {
      date: null,
      currentDate: null,
      down: Array(3),
      bottomActive: true,
      loading: true,
      counter: 0,
      width: 0,
      check1: true,
      check2: true,
      timer: 600,
      weights: [],
      pircent: 96,
      href: null
    }
  },
  created () {
    axios
      .get(this.apiUrl + '/pay?value=' + this.price, {
        params: {
          lk: 'https://lk.cheatmeal.org/auth/'
        },
        headers: {
          Authorization: this.token
        }
      })
      .then((response) => {
        if (response.data.success) {
          this.href = response.data.url
        } else {
          this.href = this.staticUrl + '/?token=' + this.token
        }
        // this.href = response.data.confirmation.confirmation_url
        this.loading = false
      })
      .catch(() => {
        this.href = this.staticUrl + '/?token=' + this.token
        this.loading = false
      })
    document.getElementById('content').addEventListener('scroll', this.handleShowButton)
    window.dataLayer.push({ event: 'sendMail' })
    window.addEventListener('resize', this.updateWidth)
    this.updateWidth()
    this.timerCount()
    this.weightCounter()
    this.pircentCounter()
  },
  mounted () {
    this.date = new Date()
    this.date.setDate(this.date.getDate() + 30)
    this.currentDate = new Date()
    this.currentDate.setDate(this.currentDate.getDate())
    this.down.map(() => false)
  },
  watch: {
    check: {
      handler: function () {
        this.check1 = !this.check1
        this.check2 = !this.check2
      }
    }
  },
  computed: {
    ...mapGetters([
      'config',
      'gender',
      'result',
      'price',
      'apiUrl',
      'token',
      'staticUrl'
    ]),
    currentWeight: function () {
      return Array.apply(null, Array(5)).map((element, index) => index + (this.result !== null ? this.result.want_weight : 0) - 2)
    },
    currentMonth: function () {
      return this.currentDate?.getDate() + ' ' + this.config.months[(this.currentDate?.getMonth()) % 12]?.rod
    },
    nextMonth: function () {
      return this.date?.getDate() + ' ' + this.config.months[(this.date?.getMonth()) % 12]?.rod
    },
    getIMT: function () {
      return (this.result.parameters[1] * 10000 / (this.result.parameters[0] * this.result.parameters[0])).toFixed(2)
    },
    getIMTStatus: function () {
      switch (true) {
        case this.getIMT <= 16:
          return this.config.result.profile.indexTypes[0]
        case this.getIMT <= 18.5:
          return this.config.result.profile.indexTypes[1]
        case this.getIMT < 25:
          return this.config.result.profile.indexTypes[2]
        case this.getIMT <= 30:
          return this.config.result.profile.indexTypes[3]
        default:
          return this.config.result.profile.indexTypes[4]
      }
    },
    getBMR: function () {
      let bmr, age
      switch (this.result.age) {
        case 'small':
          age = 20
          break
        case 'medium':
          age = 30
          break
        case 'large':
          age = 40
          break
        case 'x-large':
          age = 50
          break
      }
      if (this.gender === 'male') {
        bmr = 88.36 + (this.result.parameters[1] * 13.4) + (this.result.parameters[0] * 4.8) - (age * 5.7)
      } else {
        bmr = 447.6 + (this.result.parameters[1] * 9.2) + (this.result.parameters[0] * 3.1) - (age * 4.3)
      }
      switch (this.result.physical) {
        case 'none':
          bmr *= 1.2
          break
        case 'small':
          bmr *= 1.375
          break
        case 'medium':
          bmr *= 1.55
          break
        case 'large':
          bmr *= 1.725
          break
      }
      return bmr.toFixed(0)
    },
    getWater: function () {
      return (this.result.parameters[1] * 0.035 / 1.5).toFixed(2)
    },
    disabled1 () {
      if (this.loading || !this.check1) return '#'
      else return this.href
    },
    disabled2 () {
      if (this.loading || !this.check2) return '#'
      else return this.href
    }
  },
  methods: {
    handleShowButton: function ($event) {
      this.bottomActive = ($event.target.scrollTop >= 600 && $event.target.scrollTop <= 4000)
    },
    updateWidth () {
      this.width = window.innerWidth
    },
    timerCount () {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer -= 1
          this.timerCount()
        }, 1000)
      }
    },
    weightCounter () {
      if ((this.currentWeight[2] < this.result.weight) && this.result.weight - this.currentWeight[2] > 4) {
        const avrWeight = this.result.weight - this.currentWeight[2]
        const step = Math.round(avrWeight / 9)
        for (let i = this.result.weight; i >= this.currentWeight[2]; i -= step) {
          this.weights.push(i)
        }
      } else if ((this.currentWeight[2] > this.result.weight) && this.currentWeight[2] - this.result.weight > 4) {
        const avrWeight = this.currentWeight[2] - this.result.weight
        const step = Math.round(avrWeight / 9)
        for (let i = this.currentWeight[2]; i >= this.result.weight; i -= step) {
          this.weights.push(i)
        }
      } else {
        for (let i = this.result.weight; i >= (this.result.weight - 10); i -= 1) {
          this.weights.push(i)
        }
      }
    },
    pircentCounter () {
      switch (this.price) {
        case 1:
          this.pircent = 99
          break
        case 99:
          this.pircent = 88
          break
        case 490:
          this.pircent = 44
          break
        case 890:
          this.pircent = 0
          break
      }
    }
  }
}

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.success{
  width: 100%;
  height: 100vh;

  &__header{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 80px;
    width: 100%;
    background-color: #42b983;
    border: none;
    padding: 24px;
    box-sizing: border-box;

    &-logo{
      position: absolute;
      top: 16px;
      left: 32px;

      @media (max-width: 732px) {
        display: none;
      }
    }
  }

  &__timer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 732px) {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

    &-text{
      text-align: center;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      max-width: 170px;
      margin: 0 16px 0 0;

      @media (max-width: 732px) {
        text-align: start;
      }
    }

    &-counter{
      text-align: center;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
    }
  }

  &__container{
    width: 1330px;
    margin: 0 auto;

    @media (max-width: 1394px){
      width: calc(100% - 64px);
    }
  }

  &__title{
    max-width: 620px;
    margin: 42px 0;
    font-size: 42px;
    line-height: 48px;
    letter-spacing: 1px;
    color: #42b983;
    font-weight: 400;

    @media (max-width: 1060px){
      margin: 32px auto;
      font-size: 24px;
      line-height: 29px;
    }

    &-span{
      font-weight: 700;
    }
  }

  &__footer{
    position: relative;
    z-index: 5;
    width: 100%;
    background-color: #42b983;
    padding: 32px 0;

    &-container{
      width: 1330px;
      margin: 0 auto;

      @media (max-width: 1394px){
        width: calc(100% - 64px);
      }
    }

    &-list{
      max-width: 640px;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    &-item{
      margin: 0;
    }

    &-text{
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.75;
    }

    &-link{
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.75;
      cursor: pointer;
      text-decoration: none;
    }
  }

  &__button{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    position: fixed;
    left: calc(50% - 400px);
    width: 100%;
    max-width: 800px;
    height: 48px;
    bottom: 64px;
    background-color: #42b983;
    border: none;
    border-radius: 25px;
    animation: pulse 1s linear infinite;
    cursor: pointer;
    text-decoration: none;

    @media (max-width: 832px) {
      left: 16px;
      width: calc(100% - 32px);
    }

    @keyframes pulse {
      0% {
        box-shadow: 0 0 10px 0 #42b983, 0 0 10px 0 #42b983;
      }
      100% {
        box-shadow: 0 0 4px 6px rgba(66, 185, 131, 0), 0 0 4px 30px rgba(66, 185, 131, 0);
      }
    }

    &::before{
      background-color: currentColor;
      border-radius: inherit;
      bottom: 0;
      color: inherit;
      content: "";
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity .2s cubic-bezier(.4,0,.6,1);
    }
  }

}

.buy{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 42px 0;

  @media (max-width: 1060px){
    flex-direction: column;
  }

  &__left{
    width: 45%;
    display: flex;
    flex-direction: column;

    @media (max-width: 1060px){
      width: 100%;
    }
  }

  &__subtitle{
    margin: 0;
    color: #fff;
    font-size: 14px;
    line-height: 1.4em;

    &-container{
      max-width: 640px;
      background: #13b123;
      padding: 8px;
      margin: 0 0 24px 0;

      @media (max-width: 1060px){
        margin: 0 auto 24px auto;
      }
    }
  }

  &__get{
    width: 100%;
    background: #fff;
    border: 2px solid #e4e4e4;
    border-radius: 10px;
    padding: 24px;
    margin: 0;
  }

  &__image{
    max-width: 614px;
    max-height: 361px;

    @media (max-width: 1200px) {
      max-width: 500px;
      max-height: 293.97px;
    }

    @media (max-width: 1060px){
      max-width: 582px;
      max-height: 342px;
      margin: 0 auto;
      width: 100%;
    }
  }
}

.can{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto 42px auto;

  @media (max-width: 1060px){
    flex-direction: column;
  }

  &__graph{
    max-width: 610px;
    width: 50%;
    margin: 0 32px 0 0;

    @media (max-width: 1060px){
      width: 100%;
      margin: 0 auto 32px auto;
    }

    &-title{
      margin: 0 0 32px 0;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      letter-spacing: .05em;
      color: #000;
      border: none;
    }

    &-container{
      border: 2px solid #e4e4e4;
      border-radius: 10px;
      padding: 8px;
    }

    &-text{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-subtitle{
      font-size: 12px;
      color: rgba(0,0,0,.87);
    }

    &-image-container{
      display: flex;
      flex-direction: row;
      align-items: stretch;
      width: 100%;
    }

    &-counter{
      display: flex;
      align-self: stretch;
      padding: 24px 0;
      flex-direction: column;
      justify-content: space-between;
      list-style-type: none;
    }

    &-count{
      font-size: 14px;
    }

    &-image{
      max-width: 95%;
    }

  }

  &__areas{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff8f4;
    padding: 16px 24px;

    @media (max-width: 1060px){
      width: 100%;
      max-width: 610px;
      margin: 0 auto;
    }

    &-title{
      font-weight: 700;
      font-size: 21px;
      line-height: 22px;
      text-align: center;
      letter-spacing: .4px;
      color: #474763;
      padding-bottom: 13px;
    }

    &-line{
      margin: 0 0 8px 0;
      width: 100%;
      height: 1px;
      background-color: #cdcdcd;
    }

    &-svg{
      max-width: 275px;
    }

    &-subtitle{
      margin: 24px 0 0 0;
      font-size: 14px;
      line-height: 1.4em;

      & span{
        font-weight: 700;
      }
    }
  }
}

.get{
  width: 100%;
  max-width: 640px;
  background: #fff;
  border: 2px solid #e4e4e4;
  border-radius: 10px;
  padding: 24px;
  margin: 0 auto 42px auto;

  @media (max-width: 1060px) {
    margin: 0 auto 8px auto;
  }

  &__title{
    margin: 0;
    font-weight: 800;
    font-size: 24px;
    text-align: center;
    color: #323232;
  }

  &__percent{
    color: red;
    font-weight: 800;
    font-size: 24px;
    text-align: center;
    margin: 0 0 16px 0;
  }

  &__price{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 0 16px 0;

    &-old{
      text-decoration: line-through;
      color: red;
      font-weight: 400;
    }

    &-new{
      font-size: 18px;
      letter-spacing: .7px;
      font-weight: 700;
      text-align: center;
      color: #13b123;
    }
  }

  &__button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    margin: 0 0 32px 0;
    border-radius: 25px;
    border: none;
    background-color: hsl(153, 47%, 49%);
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all .2s ease-in;

    &:hover{
      background-color: hsl(153, 47%, 59%);
    }

    &.disabled{
      background: rgba(0,0,0,.12);
      color: rgba(0,0,0,.26);
      cursor: default;
    }
  }

  &__label{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 24px 0;
    cursor: pointer;
  }

  &__text{
    font-size: 8px;
    color: rgba(0,0,0,.6);
    line-height: 20px;
  }

  &__input{
    margin: 0 16px 0 0;
    max-width: 14px;
    max-height: 14px;
  }
}

.slider{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 780px;
  margin: 0 auto 42px auto;

  &__title{
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 16px 0;
  }

  &__container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0 0 32px 0;

    @media (max-width: 900px){
      justify-content: space-between;
    }
  }

  &__area{
    max-width: 680px;
    overflow: hidden;

    @media (max-width: 900px){
      max-width: 212px;
    }
  }

  &__images{
    display: flex;
    flex-direction: row;
    transition: all .4s ease-in;
  }

  &__button{
    min-width: 30px;
    height: 50px;
    margin: 0 32px 0 0;
    border: none;
    background: none;
    background-image: url('../../src/assets/img/success/icons/arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    transform: scale(-1, 1);

    &_right{
      margin: 0 0 0 32px;
      transform: none;
    }
  }

  &__image{
    width: 212px;
    height: 424px;
    margin: 0 22px 0 0;

    @media (max-width: 900px){
      margin: 0;
    }
  }

  &__control{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 190px;

    &-button{
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid #42b983;
      background: none;
      cursor: pointer;

      &_active{
        background-color: #42b983;
      }
    }
  }
}

.questions{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 880px;
  margin: 0 auto;

  &__item{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 32px 0;

    @media (max-width: 900px){
      flex-direction: column;
      align-items: center;
    }

    &:nth-of-type(2n){
      @media (max-width: 900px){
        flex-direction: column-reverse;
      }
    }
  }

  &__info{
    max-width: 440px;

    @media (max-width: 900px){
      max-width: 560px;
      margin: 0 0 32px 0;
    }
  }

  &__title{
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: .7px;
    color: #000;
  }

  &__subtitle{
    font-size: 14px;
    line-height: 1.4em;
    margin: 24px 0 0 0;
    font-weight: 400;
    letter-spacing: .7px;
  }

  &__img{

    &-container{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.recipes{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 0 42px 0;

  @media (max-width: 1060px){
    flex-direction: column;
    align-items: center;
  }

  &__item{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 32px 0 0;
    padding: 16px 32px;
    box-sizing: border-box;

    @media (max-width: 1060px){
      width: 100%;
      max-width: 640px;
    }

    &:last-child{
      margin: 0;
    }

    &_background{
      background-color: #fff8f4;
    }
  }

  &__title{
    font-weight: 700;
    font-size: 21px;
    line-height: 22px;
    text-align: center;
    letter-spacing: .4px;
    color: #474763;
    margin: 0 0 12px 0;
  }

  &__line{
    width: 100%;
    height: 1px;
    background-color: #cdcdcd;
    margin: 0 0 12px 0;
  }

  &__img{
    margin: 0 0 24px 0;
  }

  &__subtitle{
    font-style: italic;
    font-size: 18px;
    text-align: center;
  }
}

.security{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 54px 0;

  &__container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1060px;
    border: 3px solid #009962;
    border-radius: 3px;
    padding: 20px 20px 40px;

    &::before{
      content: '';
      width: 82px;
      height: 82px;
      background-image: url('../../src/assets/img/success/security/1.png');
      background-size: cover;
      position: absolute;
      bottom: -41px;
      right: 200px;
    }

    &::after{
      content: '';
      width: 82px;
      height: 82px;
      background-image: url('../../src/assets/img/success/security/2.png');
      background-size: cover;
      position: absolute;
      bottom: -41px;
      right: 100px;
    }
  }

  &__title{
    margin: 0;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    color: #000;
  }

  &__subtitle{
    font-weight: 300;
    font-size: 22px;
    text-align: center;
    color: #000;
    margin: 25px 0 0 0;
  }
}

.info{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 32px 0;

  @media (max-width: 1060px){
    flex-direction: column;
    align-items: center;
  }

  &__column{
    width: 50%;
    padding: 0 16px 0 0;
    box-sizing: border-box;

    @media (max-width: 1060px){
      width: 100%;
      max-width: 620px;
    }

    &:last-child{
      padding: 0 0 0 16px
    }
  }

  &__title{
    font-size: 22px;
    color: #000;
    font-weight: 700;
    margin: 0 0 24px 0;
    line-height: 1.5;

    &_color{

      &_green{
        color: #42b983;
      }
    }
  }

  &__subtitle{
    font-size: 22px;
    color: #000;
    font-weight: 400;
    line-height: 1.5;
    margin: 0 0 24px 0;
  }

  &__list{
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-width: 510px;

    @media (max-width: 1060px){
      margin: 0 0 32px 0;
    }

    &-item{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0;
      border-bottom: 1px solid #cdcdcd;
    }

    &-img{
      margin: 0 16px 0 0;
    }
  }

  &__text{
    margin: 0 0 24px 0;
    font-size: 14px;
    line-height: 1.21;
    color: #323232;
  }
}

.payments{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 32px 0;

  &__container{
    width: 100%;
    max-width: 268px;
  }

  &__title{
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: #000;
    margin: 12px 0;
  }

  &__list{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;

    &-img{
      max-width: 50px;
      margin: 0 16px 0 0;
    }
  }
}

</style>
