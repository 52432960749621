<template>
<div :class="['answer', data.gender, {'active': active}]" @click="toggleActive">
  <img class="answer__img" :src="require('../../../public/img/answers/step-' + data.step + '/' + data.data.img)" alt="">
  <div class="answer__text">
    <p class="answer__text-title">{{ data.data.title }}</p>
    <p class="answer__text-description">{{ data.data.description }}</p>
  </div>
</div>
</template>

<script>
export default {
  name: 'answer1',
  props: {
    data: {
      type: Object
    }
  },
  data: function () {
    return {
      active: false
    }
  },
  methods: {
    toggleActive: function () {
      this.$emit('choose', { key: this.data.key })
      this.active = !this.active
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.answer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 28px 32px 28px 32px;
  margin-bottom: 16px;
  background: #eaeef2;
  border-radius: 10px;
  cursor: pointer;

  @media (max-width: 980px){
    padding: 0;
    align-items: center;
  }

  &__img{
    display: none;

    @media (max-width: 980px){
      display: block;
      max-width: 100px;
    }
  }

  &__text{

    &-title{
      color: #162a41;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  &:last-child{
    margin: 0;
  }
}

</style>
