<template>
  <div class="prelend">
    <div class="prelend__container">
      <h1 class="prelend__title">{{ config?.prelend.title }}</h1>
      <p class="prelend__subtitle">{{ config?.prelend.subtitle }}
        <span class="prelend__subtitle-span">{{ config?.prelend.subtitleSpan }}</span>
      </p>
      <p class="prelend__text">{{ config?.prelend.text }}
        <span class="prelend__text-span">{{ config?.prelend.textSpan }}</span>
      </p>
      <ul class="prelend__list">
        <li class="prelend__list-item">
          <button class="prelend__button" @click="setGender('female')">
            <img class="prelend__button-img" :src="require('@/assets/img/prelend/1.png')" alt="18-24"/>
            <p class="prelend__button-text">Возраст: 18-24</p>
          </button>
        </li>
        <li class="prelend__list-item">
          <button class="prelend__button" @click="setGender('female')">
            <img class="prelend__button-img" :src="require('@/assets/img/prelend/2.png')" alt="25-34"/>
            <p class="prelend__button-text">Возраст: 25-34</p>
          </button>
        </li>
        <li class="prelend__list-item">
          <button class="prelend__button" @click="setGender('female')">
            <img class="prelend__button-img" :src="require('@/assets/img/prelend/3.png')" alt="35-44"/>
            <p class="prelend__button-text">Возраст: 35-44</p>
          </button>
        </li>
        <li class="prelend__list-item">
          <button class="prelend__button" @click="setGender('female')">
            <img class="prelend__button-img" :src="require('@/assets/img/prelend/4.png')" alt="45+"/>
            <p class="prelend__button-text">Возраст: 45+</p>
          </button>
        </li>
      </ul>
      <div class="prelend__info">
        <h3 class="prelend__info-title">*Подробнее о тарифах сервиса</h3>
        <p class="prelend__info-text">ИНН 9703059075, ОГРНИП 1217700551529</p>
        <p class="prelend__info-text">ООО "СП СОЛЮШЕН"</p>
        <p class="prelend__info-text">
          <a class="prelend__info-link" href="terms" target="_blank">Оферта и Правила сервиса</a>
          <a class="prelend__info-link" href="policies" target="_blank">Политика конфиденциальности</a>
        </p>
        <p class="prelend__info-text">
          <a class="prelend__info-link" href="tarifs" target="_blank">Тарифы</a>
          <a class="prelend__info-link" href="https://cheatmeal.pro/unsubscribe/" target="_blank">
            Отказаться от подписки
          </a>
        </p>
        <div class="prelend__info-down">
          <p class="prelend__info-text">125039, г. Москва, ПРЕСНЕНСКАЯ НАБЕРЕЖНАЯ, д. 10, стр. 2, ПОМЕЩ. 97, КОМ. 2, ОФ. 254</p>
          <p class="prelend__info-text">help@cheatmeal.pro | +79161789549</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'prelend',
  components: {},
  created () {
    // if (this.config && this.gender && this.result && ('email' in this.result) && this.token) {
    //   window.location.href = 'https://lk.cheatmeal.pro/auth/' + this.token
    // }
  },
  computed: {
    ...mapGetters([
      'config',
      'gender',
      'result',
      'step',
      'apiUrl',
      'token',
      'steps'
    ])
  },
  methods: {
    ...mapActions([
      'setGender'
    ])
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.prelend {
  width: 100%;
  height: 100%;
  position: relative;

  &__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1600px;
    height: 100%;
    padding: 192px 0 0 0;
    margin: 0 auto;
    background-image: url('../assets/img/back.png');
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 1664px){
      width: calc(100% - 64px);
    }

    @media (max-width: 870px){
      padding: 64px 0 0 0;
      background: none;
    }

    @media (max-width: 678px){
      width: calc(100% - 32px);
    }

    @media (max-width: 448px){
      width: calc(100% - 16px);
    }
  }

  &__title{
    margin: 0 0 8px 0;
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    color: #162a41;
    letter-spacing: .01em;
    text-align: center;
  }

  &__subtitle{
    margin: 0 0 68px 0;
    font-size: 16px;
    line-height: 2;
    font-weight: 400;
    color: #162a41;
    letter-spacing: .01em;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 870px){
      margin: 0 0 32px 0;
    }

    &-span{
      font-weight: 700;
    }
  }

  &__text{
    margin: 0 0 34px 0;
    color: #4e657f;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: .01em;
    line-height: 16px;
    text-align: center;

    &-span{
      font-weight: 700;
    }
  }

  &__list{
    display: flex;
    flex-direction: row;
    margin: 0 0 48px 0;
    padding: 0;
    list-style-type: none;

    @media (max-width: 870px){
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    &-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
      flex: 1 0 25%;

      @media (max-width: 448px){
        padding: 16px 8px;
      }
    }
  }

  &__button{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 232px;
    min-width: 160px;
    position: relative;
    border: 2px solid #50c9f5;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgb(103 125 150 / 10%), 0 2px 6px rgb(22 42 65 / 6%);
    cursor: pointer;

    @media (max-width: 870px){
      width: 100%;
    }

    @media (max-width: 386px){
      min-width: auto;
      height: 186px;
    }

    &-img{
      position: relative;
      bottom: 16px;
      height: 206px;

      @media (max-width: 386px){
        height: 160px;
      }
    }

    &-text{
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 38px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #50c9f5;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: .7px;
      text-shadow: 0 1px 1px rgb(42 56 107 / 20%);

      &::after{
        margin: 0 0 0 4px;
        width: 14px;
        height: 14px;
        content: '';
        background-image: url('../assets/img/icons/next.png');
        background-size: cover;
      }
    }
  }

  &__info{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    margin: 0 0 32px 0;

    &-down {
      margin: 4px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-title{
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      letter-spacing: -.02em;
      color: rgba(0,0,0,.87);
    }

    &-text{
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      letter-spacing: -.02em;
      color: rgba(0,0,0,.87);
    }

    &-link{
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      letter-spacing: -.02em;
      color: rgba(0,0,0,.87);
      cursor: pointer;
      text-decoration: none;

      &:first-child {
        &::after {
          margin: 0 4px;
          content: '|';
        }
      }
    }
  }
}
</style>
