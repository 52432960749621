<template>
<div :class="['step', step === 18 ? 'step_email' : '']">
    <div :class="['step__back', step === 18 ? 'step__back_display_none' : '']">
      <img class="step__back-img" :src="step < 18 ? require('../../public/img/answers/step-' + step + '/' + data.img) : ''" />
    </div>
    <div :class="['step__answers', step === 18 ? 'step__answers_email' : '']">
      <div :class="['step__answers-container', step === 18 ? 'step__answers-container_email' : '']">
        <div :class="['step__progress', step < 18 ? '' : 'step__progress_display_none']">
          <div class="step__progress-active" v-bind:style="{width: (step - 1)*6.25 + '%' }"></div>
        </div>
        <div :class="['step__counter', step === 18 ? 'step__counter_display_none' : '']">
          <p class="step__counter-active">{{step - 1}}</p>
          <p class="step__counter-all">/ 16</p>
        </div>
        <h1 class="step__title" v-if="data.title">{{ data.title }}</h1>
          <component
            :is="'answer' + getAnswerTypeNumber"
            v-for="(index, key) in data.answers"
            :key="step+'.'+key"
            :data="{data: index, gender, step, key, answers: data.answers.length, checked: isChecked(key)}"
            @choose="choose"
          />
          <div :class="['step__button-container', step === 18 ? 'step__button-container_email' : '']">
            <span :class="['step__error', step === 18 && !buttonActive && validError ? 'step__error_show' : '']">Напишите действительную электронную почту и примите соглашение ниже</span>
            <button
              :class="['step__button', step === 18 ? 'step__button_email' : '']"
              v-if="data.type !== 'one'"
              :disabled="!buttonActive"
              @click="ready" >
              {{ step === 18 ? !loading ? 'Получить план' : 'Пожалуйста, подождите' : !loading ? 'Далее' : 'Пожалуйста, подождите'}}
              <div :class="['step__button--wrapper', step === 18 && !buttonActive ? 'step__button--wrapper_active' : '']" @click="showError"></div>
            </button>
          </div>
      </div>
    </div>
</div>
</template>

<script>

import answers from '@/components/answers'
import steps from '@/components/Steps'
import axios from 'axios'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import router from '@/router'

export default {
  name: 'step',
  components: {
    steps, ...answers
  },
  data () {
    return {
      checked: [],
      checked_statuses: null,
      error: null,
      custom_button: [3, 21],
      readyResult: false,
      loading: false,
      validError: false
    }
  },
  computed: {
    ...mapGetters([
      'config',
      'gender',
      'step',
      'isStepScrollable',
      'token',
      'apiUrl',
      'checkEmail'
    ]),
    data: function () {
      return this.config[this.gender]?.steps[this.step]
    },
    buttonActive: function () {
      this.setValidError(false)
      const step = this.config[this.gender].steps[this.step]
      const validate = step.answers.map((field, key) => {
        const value = this.checked[key]
        switch (field.type) {
          case 'number': return ((field.min <= this.checked) && (this.checked <= field.max))
          case 'string': return this.validEmail(this.checked)
          case 'none': return true
          case undefined: return !!(this.checked)
          default: return value
        }
      })

      const validated = validate.filter(f => !!f || f === 0)
      const unvalidated = validate.filter(f => !f)

      const result = {
        one: validated.length === 1,
        some: validated.length > 0,
        some_or_none: validated.length > 0,
        default: unvalidated.length === 0
      }

      return result[step.type] || result.default
    },
    getAnswerTypeNumber: function () {
      const data = this.config[this.gender].steps[this.step].answers[0]
      switch (data.type) {
        case 'number': return 5
        case 'select': return 6
        case 'string': return 9
        case 'none': return 10
        default: break
      }
      if (data.title === false) return 12

      if (data.img === false) return 3

      if (data.direction === 'column-reverse') return 7
      else if (data.direction === 'column') {
        if (data.description === false) return 8
        else return 11
      }

      if (data.inRow === 2) return 4

      if (data.description === false) return 2
      else return 1
    }
  },
  methods: {
    ...mapMutations([
      'RESULT'
    ]),
    ...mapActions([
      'setGender',
      'setValidError'
    ]),
    isChecked: function (idx) {
      if (this.data.type === 'some_or_none') {
        return this.checked_statuses[idx]
      } else if (this.data.type === 'some') {
        if (Array.isArray(this.checked)) {
          return (this.checked.indexOf(idx) !== -1)
        } else return false
      } else return false
    },
    createRange: function (start, count) {
      return Array.apply(0, Array(count))
        .map(() => false)
    },
    choose (params) {
      switch (this.data.type) {
        case 'one': {
          this.checked = params.key
          this.$emit('next', this.checked)
          break
        }
        case 'number': {
          this.checked = params.params
          break
        }
        case 'string': {
          this.checked = params.params
          break
        }
        case 'select': {
          if (!Array.isArray(this.checked)) {
            this.checked = []
          }
          this.checked[params.key] = params.params
          break
        }
        case undefined: {
          if (!this.checkEmail && params.params) {
            this.checked = params.params
            this.readyResult = true
          } else {
            this.checked = true
          }
          break
        }
        case 'some_or_none': {
          if (!Array.isArray(this.checked)) {
            this.checked = []
          }

          this.checked_statuses[params.key] = !this.checked_statuses[params.key]
          if (params.key === this.checked_statuses.length - 1) {
            for (let i = 0; i < this.checked_statuses.length - 1; i++) {
              this.checked_statuses[i] = false
            }
            if (this.checked_statuses[params.key]) {
              this.checked = [params.key]
            } else {
              this.checked = []
            }
          } else {
            if (this.checked_statuses[this.checked_statuses.length - 1]) {
              this.checked_statuses[this.checked_statuses.length - 1] = false
              this.checked.splice(this.checked.indexOf(this.checked_statuses.length - 1), 1)
            }
            if (this.checked_statuses[params.key]) {
              this.checked.push(params.key)
            } else {
              this.checked.splice(this.checked.indexOf(params.key), 1)
            }
          }
          break
        }
        case 'some': {
          if (!Array.isArray(this.checked)) {
            this.checked = []
          }

          const index = this.checked.indexOf(params.key)
          if (index === -1) this.checked.push(params.key)
          else this.checked.splice(index, 1)
        }
      }
    },
    ready: function () {
      if (this.readyResult) {
        axios
          .get(this.apiUrl + '/user/questions', {
            headers: {
              Authorization: this.token
            }
          })
          .then((response) => {
            if (response.data.email) {
              this.RESULT({ ...response.data.questions, email: response.data.email })
              router.push({ name: 'Choose' })
            } else {
              this.loading = false
            }
          })
      } else if (this.data.type !== 'string') {
        this.$emit('next', this.checked)
        this.checked = []
      } else {
        this.loading = true
        axios
          .patch(this.apiUrl + '/user/email', {
            email: this.checked,
            gender: this.gender
          }, {
            headers: {
              Authorization: this.token
            }
          })
          .then((response) => {
            response = response.data

            this.RESULT({ ...response.questions, email: response.email })
            this.setGender(response.gender)
            router.push({ name: 'Choose' })
          })
          .catch(() => {
            this.$store.dispatch('setModal', 'Error')
          })
      }
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    showError: function () {
      this.validError = true
    }
  },
  watch: {
    data: function () {
      if (this.data?.type === 'some_or_none') {
        this.checked_statuses = this.createRange(0, this.data.answers.length)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.step{
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  &_email {
    background-color: #bbb;
    align-items: center;
    justify-content: center;
  }

  &__back{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 57%;
    min-height: 100vh;
    background: linear-gradient(rgba(104,223,250,.08),rgba(104,223,250,.25));

    &_display {

      &_none {
        display: none;
      }
    }

    &-img {
      max-width: 768px;
      max-height: 80vh;

      @media (max-width: 1310px){
        max-width: 640px;
      }

      @media (max-width: 1124px){
        max-width: 520px;
      }
    }

    @media (max-width: 980px){
      display: none;
    }
  }

  &__progress{
    width: 100%;
    height: 4px;
    background-color: #F4F5F8;
    margin: 48px 0 0 0;

    &-active{
      height: 4px;
      background-color: rgb(104, 223, 250);
    }

    &_display{
      &_none{
        display: none;
      }
    }
  }

  &__counter{
    margin: 24px 0 0 0;
    display: flex;
    flex-direction: row;
    align-self: flex-end;

    &-active{
      margin: 0 8px 0 0 ;
      font-size: 16px;
      font-weight: 700;
      color: rgb(104, 223, 250);
    }

    &-all{
      font-size: 16px;
      font-weight: 700;
      color: #162a41;
    }

    &_display {

      &_none {
        display: none;
      }
    }
  }

  &__answers{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 43%;
    padding: 0 16px;

    @media (max-width: 980px){
      width: 100%;
    }

    &_email{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-height: 560px;
      max-width: 560px;
      background-color: #fff;
      padding: 8px;
      border-radius: 15px;
    }

    &-container{
      position: relative;
      display: flex;
      flex-direction: column;
      width: 460px;

      &_email{
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__title{
    margin: 24px 0 32px 0;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25;
    color: #162a41;
    text-align: center;

    @media (max-width: 980px){
      font-size: 24px;
    }
  }

  &__button{
    position: relative;
    margin: 16px 0 0 0;
    width: 200px;
    height: 49px;
    background-color: rgb(104, 223, 250);
    border: none;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: 980px) {
      width: 100%;
    }

    &:disabled{
      opacity: .5;
      cursor: default;
    }

    &--wrapper {
      position: absolute;
      top: 0;
      width: 100%;
      max-width: 380px;
      height: 52px;
      display: none;

      &_active {
        display: block;
      }
    }

    &-container{

      &_email {
        position: absolute;
        bottom: 125px;
        width: 100%;
        max-width: 380px;
        opacity: 1;
      }
    }

    &_email{
      display: block;
      margin: 48px 0 0 0;
      width: 100%;
      height: 52px;
      border-radius: 10px;
      font-size: 18px;
      opacity: 1;

      &:disabled{
        opacity: .5;
      }
    }
  }

  &__error {
    display: none;
    font-size: 11px;
    color: red;

    &_show {
      display: block;
    }
  }
}

</style>
