<template>

  <div
    id="no-light"
    v-show="isBlackout"
    @click="disableBlackout"
  ></div>

  <div id = "content" :class="['content', {'scrollable': isScrollable}]">
    <div class="container">
      <router-view />
    </div>
  </div>

  <component
    v-if="modal"
    :is="'Modal' + modal"
  />
</template>

<script>
import axios from 'axios'
import modals from '@/components/modals'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import 'typeface-montserrat-cyrillic'

export default {
  components: {
    ...modals
  },
  data () {
    return {
      vh: window.innerHeight * 0.01
    }
  },
  mounted () {
    console.log('route', this.$route.query)
    window.addEventListener('resize', this.updateHeight)
    this.updateHeight()
    axios
      .get('lang/' + this.lang + '.json')
      .then(response => {
        this.CONFIG(response.data)
      })
    // this.createUser()
  },
  computed: {
    isScrollable: function () {
      return (
        (['Home', 'faq', 'terms', 'policies', 'Result', 'Pay'].indexOf(this.$route.name) !== -1) ||
        (this.isStepScrollable)
      )
    },
    ...mapGetters([
      'modal',
      'config',
      'lang',
      'menu',
      'isBlackout',
      'isStepScrollable'
    ])
  },
  methods: {
    ...mapMutations([
      'TOGGLE_MENU',
      'CLOSE_MENU',
      'CONFIG'
    ]),
    ...mapActions([
      'disableBlackout',
      'goHome',
      'createUser',
      'setModal'
    ]),
    updateHeight () {
      document.documentElement.style.setProperty('--vh', `${this.vh}px`)
    }
  }
}

</script>

<style lang="scss">
@import "./src/assets/main.scss";

#app {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
}

#no-light {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000055;
  z-index: 50;
}

header {
  width: 100%;
  height: 56px;
  border-bottom: 1px solid $line-color;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  img {
    width: auto;
    height: 45px;
    padding-bottom: 5px;
    margin-left: 10px;
  }

  #nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 310px;
    height: 100%;
    transform: translateX(370px);
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px 30px 30px;
    border-left: 1px solid $line-color;
    transition-duration: .5s;
    transition-timing-function: ease;
    will-change: transform;
    z-index: 100;
    overflow-y: auto;

    &.active {
      transform: translateX(0);
    }

    a {
      font-weight: bold;
      margin-bottom: 2em;
      text-decoration: none;
      color: black;
      font-size: 20px;

      &.router-link-exact-active {
        color: #42b983;
      }
    }

    .cancel {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }
}

.title {
  width: 100%;
  text-align: center;
}
</style>
