<template>
  <div :class="['answer', data.gender, {'active': active}]" @click="toggleActive">
    <img :src="require('@/assets/img/' + data.gender + '/step-' + data.step + '/' + data.data.img)" alt="">
    <div class="text">
      <p class="title">{{ data.data.title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'answer4',
  props: {
    data: {
      type: Object
    }
  },
  data: function () {
    return {
      active: false
    }
  },
  mounted: function () {
    this.active = this.data.checked
  },
  methods: {
    toggleActive: function () {
      this.$emit('choose', { key: this.data.key })
      this.active = !this.active
    }
  },
  watch: {
    data: function () {
      this.active = this.data.checked
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.answer {
  width: 45%;
  height: 70px;
  padding: 15px 20px;
  margin-top: 30px;
  border: 1px solid $line-color;
  box-shadow: 0 0 30px -20px black;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: .5s;

  @media (max-height: 640px) {
    margin-top: 20px;
  }

  @media (max-height: 600px) {
    height: 60px;
  }

  @media (max-height: 560px) {
    margin-top: 10px;
  }

  @media (max-height: 520px) {
    height: 50px;
  }

  @media (max-width: 370px) {
    padding: 10px;
  }

  @media (max-width: 350px) {
    width: 47%;
  }

  &.male:hover {
    background-color: transparentize($color-male-tertiary, .7);
  }

  &.male.active {
    background-color: transparentize($color-male-primary, .7);
  }

  &.female:hover {
    background-color: transparentize($color-female-tertiary, .7);
  }

  &.female.active {
    background-color: transparentize($color-female-primary, .7);
  }

  img {
    width: auto;
    height: 100%;

    @media (max-width: 420px) {
      height: 80%;
    }

    @media (max-width: 335px) {
      height: 70%;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;

    @media (max-width: 400px) {
      font-size: .9em;
    }

    @media (max-width: 370px) {
      padding-left: 5px;
    }

    @media (max-width: 350px) {
      font-size: .8em;
    }
  }
}

</style>
