<template>
  <div class="custom-select" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ selected }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(i) of range(data.data.min, data.data.max)"
        :key="i"
        @click="
          selected = i;
          open = false;
          $emit('choose', { key: data.key, params: i })
        "
      >
        {{ i }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'answer6',
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      selected: null,
      open: false
    }
  },
  mounted () {
    this.selected = this.data.data.name
  },
  methods: {
    range: function (start, end) {
      return Array.apply(0, Array(end - start + 1))
        .map((element, index) => index + start)
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.custom-select {
  position: relative;
  width: 100%;
  padding: 12px 9px 12px 12px;
  border-radius: 12px;
  border: 2px solid #dde5ed;
  outline: 0 none;
  color: #162a41;

  .items {
    background: white;
    color: black;
    border-radius: 0 0 6px 6px;
    overflow: hidden;
    border-right: 1px solid $line-color-dark;
    border-left: 1px solid $line-color-dark;
    border-bottom: 1px solid $line-color-dark;
    position: absolute;
    left: 0;
    right: 0;
    height: 500%;
    overflow-y: auto;
    z-index: 2;

    div {
      color: black;
      padding-left: 1em;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: $line-color;
      }
    }
  }

  .selected {
    border-radius: 6px;
    border: 1px solid #666666;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    position: relative;

    &.open {
      border: 1px solid $line-color-dark;
      border-radius: 6px 6px 0 0;
    }

    &:after {
      content: '';
      width: 7px;
      height: 7px;
      border-bottom: 2px solid $line-color-dark;
      border-left: 2px solid $line-color-dark;
      transform: rotate(-45deg) translateY(-50%);
      position: absolute;
      right: 20px;
      top: 45%;
    }
  }
}

.selectHide {
  display: none;
}

</style>
