import { createStore } from 'vuex'
import router from '@/router'
import axios from 'axios'

export default createStore({
  state: {
    steps: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
    config: {
      lang: 'ru',
      menu: false,
      gender: null,
      config: null,
      result: null,
      step: 1,
      price: null,
      validError: false
    },
    modal: {
      modal: null,
      cost: 25
    },
    api: {
      token: window.localStorage.getItem('token'),
      url: 'https://api.cheatmeal.pro',
      static: 'https://lk.cheatmeal.pro'
    },
    check_email: true,
    debug: true,
    url_params: null
  },
  mutations: {
    SHORT_TEST: (state) => {
      state.steps = [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 17, 18, 19, 21]
    },

    SHORT_LIGHT: (state) => {
      state.steps = [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 17, 18, 19, 22]
      state.check_email = false
    },

    URL_PARAMS: (state, params) => {
      console.log('here', params)
      state.url_params = params
    },

    DEFAULT: (state) => {
      state.config.menu = false
      state.config.gender = null
      state.config.result = null
      state.config.step = 1
      state.modal.modal = null
      state.modal.cost = 25
    },

    TOKEN: (state, token) => {
      state.api.token = token
      window.localStorage.setItem('token', token)
    },

    NEXT_STEP: state => { state.config.step = state.steps[state.steps.indexOf(state.config.step) + 1] },

    TOGGLE_MENU: state => { state.config.menu = !state.config.menu },

    CLOSE_MENU: state => { state.config.menu = false },

    CONFIG: (state, value) => { state.config.config = value },

    GENDER: (state, value) => { state.config.gender = value },

    PRICE: (state, value) => { state.config.price = value },

    VALID_ERROR: (state, value) => { state.config.validError = value },

    COST: (state, value = 0) => { state.modal.cost = value },

    RESULT: (state, value) => {
      state.config.result = value
      // if (
      //   ((state.config.result.goal === state.config.config[state.config.gender].steps[2].answers[0].value) &&
      //   (state.config.result.parameters[1] > state.config.result.want_weight)) ||
      //   ((state.config.result.goal === state.config.config[state.config.gender].steps[2].answers[1].value) &&
      //   (state.config.result.parameters[1] < state.config.result.want_weight))
      // ) {
      //   const tmp = state.config.result.parameters[1]
      //   state.config.result.parameters[1] = state.config.result.want_weight
      //   state.config.result.want_weight = tmp
      // }
      delete state.config.result.none
    },

    MODAL: (state, value = null) => {
      if (value !== null) {
        state.modal.modal = value
      } else {
        switch (state.modal.modal) {
          case 'Pay':
            state.modal.modal = (state.modal.cost === 25) ? 'PayDecline' : null
            break
          case 'PayDecline':
            state.modal.cost = 1
            router.push({ name: 'Pay' })
            state.modal.modal = null
            break
          default:
            state.modal.modal = null
            break
        }
      }
    },

    NEW_TOKEN: (state) => {
      if (state.debug) console.log('start register user')

      const params = (Object.keys(state.url_params).length !== 0) ? { params: state.url_params } : {}

      axios
        .post(state.api.url + '/register', params)
        .then((response) => {
          state.api.token = response.data.token
          window.localStorage.setItem('token', response.data.token)
          if (state.debug) {
            console.log('success register user')
          }
        })
    }
  },
  getters: {
    debug: state => { return state.debug },
    steps: state => { return state.steps },
    config: state => { return state.config.config },
    gender: state => { return state.config.gender },
    price: state => { return state.config.price },
    validError: state => { return state.config.validError },
    menu: state => { return state.config.menu },
    lang: state => { return state.config.lang },
    result: state => { return state.config.result },
    step: state => { return state.config.step },
    modal: state => { return state.modal.modal },
    cost: state => { return state.modal.cost },
    token: state => { return state.api.token },
    apiUrl: state => { return state.api.url },
    staticUrl: state => { return state.api.static },
    checkEmail: state => { return state.check_email },
    urlParams: state => { return state.url_params },

    isStepScrollable (state) {
      return [5, 8, 9, 10, 11, 13, 14, 17, 18, 19].indexOf(state.config.step) !== -1
    },

    isBlackout (state) {
      return state.config.menu || (state.modal.modal !== null)
    }
  },
  actions: {
    nextStep: (context, item) => {
      context.commit('NEXT_STEP')
      axios
        .patch(context.getters.apiUrl + '/user/question', {
          key: item.key,
          value: item.value
        }, {
          headers: {
            Authorization: context.getters.token
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },

    setModal: ({ commit }, value = null) => {
      commit('MODAL', value)
    },

    disableBlackout: ({ commit }) => {
      commit('CLOSE_MENU')
      commit('MODAL')
    },

    urlParams: (context, value) => {
      context.commit('URL_PARAMS', value)
      context.dispatch('createUser')
    },

    createUser: (context) => {
      console.log('test params', router.query)
      console.log('params', context.getters.urlParams)
      if (!context.getters.token || !window.localStorage.getItem('token')) {
        context.commit('NEW_TOKEN')
      } else {
        axios
          .post(context.getters.apiUrl + '/token', {}, {
            headers: {
              Authorization: context.getters.token
            }
          })
          .then((response) => {
            if (context.getters.debug) console.log('user check success')
          })
          .catch(() => {
            context.commit('NEW_TOKEN')
          })
      }
    },

    setGender: (context, value) => {
      context.commit('GENDER', value)
      context.commit('NEXT_STEP')
      axios
        .patch(context.getters.apiUrl + '/user/gender/' + value, [], {
          headers: {
            Authorization: context.getters.token
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    setPrice: (context, value) => {
      context.commit('PRICE', value)
    },

    setValidError: (context, value) => {
      context.commit('VALID_ERROR', value)
    },

    goHome: (context) => {
      console.log(context.getters.result)
      if (context.getters.result && context.getters.result.email) {
        context.dispatch('setModal', 'BackHome')
      } else {
        context.commit('DEFAULT')
        router.push({ name: 'Home' })
      }
    }
  },
  modules: {
  }
})
