<template>
<div class="modal">
  <h1>Вы хотите пройти тест заново?</h1>
  <div class="row">
    <button :class="gender" @click="home">Да</button>
    <button :class="gender" @click="closeModal">Нет</button>
  </div>
  <div
    class="cancel"
    @click="closeModal"
  ></div>
</div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import router from '@/router'

export default {
  name: 'ModalPay',
  computed: {
    ...mapGetters([
      'gender',
      'apiUrl',
      'token'
    ])
  },
  methods: {
    ...mapMutations([
      'DEFAULT'
    ]),
    ...mapActions([
      'setModal',
      'setGender'
    ]),
    closeModal: function () {
      this.setModal()
    },
    home () {
      axios
        .patch(this.apiUrl + '/user/email', {
          email: null
        }, {
          headers: {
            Authorization: this.token
          }
        })
        .then(() => {
          this.DEFAULT()
          router.push({ name: 'Home' })
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.cancel {
  position: absolute;
  top: 10px;
  right: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  button {
    padding: 10px 20px;
    border-radius: 50px;
    border: 1px solid $line-color;
    color: white;
    font-size: 20px;
    background-color: $line-color-dark;

    &.male {
      background-color: $color-male-primary;
    }

    &.female {
      background-color: $color-female-primary;
    }
  }
}
</style>
