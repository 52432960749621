<template>
<div id="steps" :class="[{'prelend': prelend}]">
  <div class="info" v-if="prelend">
    <span>{{ config?.prelend.agree }}</span>
    <div class="row row_center">
      <router-link :to="item.href" v-for="(item, key) in config?.prelend.terms" :key="key">{{ item.title }}</router-link>
      <a href="https://cheatmeal.pro/unsubscribe/">Отмена подписки</a>
    </div>
    <span>{{ config?.prelend.copyright }}</span>
  </div>
  <div :class="['steps', {'steps-prelend': prelend}]">
  </div>
  <div class="info" v-if="prelend">
    <div class="row">
      <span>{{ config?.prelend.IP }}</span>
      <span>{{ config?.offer.inn }}</span>
      <span>{{ config?.offer.ogrnip }}</span>
      <span>{{ config?.prelend.address }}</span>
      <span style="padding-right: 3em;">help@cheatmeal.pro | +79161789549</span>
    </div>
  </div>
</div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'steps',
  data: function () {
    return {
      width: 0,
      show_steps: 4
    }
  },
  props: {
    prelend: {
      type: Boolean,
      default: false
    }
  },
  created () {
    window.addEventListener('resize', this.updateWidth)
    this.updateWidth()
  },
  computed: {
    ...mapGetters([
      'config',
      'gender',
      'step',
      'steps'
    ]),
    stepsLength () {
      return (this.steps.length < 19) ? this.steps.length - 1 : this.steps.length - 2
    },

    getSteps: function () {
      const current = this.steps.indexOf(this.step)

      if (current <= this.show_steps / 2) return this.steps.slice(0, this.show_steps)
      else if (current >= this.stepsLength - this.show_steps / 2) return this.steps.slice(this.stepsLength - this.show_steps, this.stepsLength)
      else return this.steps.slice(current - this.show_steps / 2, current + this.show_steps / 2)
    }
  },
  methods: {
    updateWidth: function () {
      this.width = window.innerWidth
      this.show_steps = (this.width >= 500) ? 6 : 4
      return this.width
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

#steps {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;

  .row{
    &_center{
      text-align: center;
    }
  }

  .info {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    //@media (max-width: 420px) {
    //  .row {
    //    display: flex;
    //    flex-direction: column;
    //    justify-content: center;
    //    align-items: center;
    //  }
    //}

    &:last-child {
      margin-top: 7px;
      margin-bottom: 0;

      span {
        display: block;
        width: 100%;

        @media (max-width: 320px) AND (max-height: 490px) {
          font-size: .5em;
        }
      }
    }

    span, a {
      font-size: .7em;
      color: $line-color-dark;
      text-align: center;

      @media (max-width: 400px), (max-height: 520px) {
        font-size: .6em;
      }
    }

    a {
      margin-right: 10px;
      position: relative;

      @media (max-width: 320px) {
        margin-right: 6px;
      }

      &:after {
        content: '|';
        position: absolute;
        right: -8px;
        top: 0;

        @media (max-width: 320px) {
          right: -6px;
        }
      }

      &:last-child {
        margin-right: 0;

        &:after {
          content: '';
        }
      }
    }
  }

  .steps {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    justify-self: flex-end;

    &-prelend {

      @media (max-width: 499px) {
        width: 70%;
        margin: 0 auto;
      }
    }
  }

  .step {
    width: 54px;
    height: 54px;
    position: relative;
    border-radius: 6px;
    border: 1px solid $line-color-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    @media (max-height: 520px){
      width: 40px;
      height: 40px;
      padding: 4px;
    }

    img, object {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    object svg {
      fill: red !important;
    }

    &:before {
      content: '';
      position: absolute;
      top: -15px;
      left: 0;
      right: 0;
      height: 4px;
      border: 1px solid $line-color-dark;
      border-radius: 4px;
    }

    &.current.male {
      background-color: transparentize($color-male-tertiary, 0.5);
    }

    &.current.female {
      background-color: transparentize($color-female-tertiary, 0.5);
    }

    &.complete.male {
      border-color: $color-male-secondary;

      &:before {
        border-color: $color-male-secondary;
        background-color: $color-male-secondary;
      }
    }

    &.complete.female {
      border-color: $color-female-secondary;

      &:before {
        border-color: $color-female-secondary;
        background-color: $color-female-secondary;
      }
    }
  }
}

</style>
