<template>
  <div class="container">
  <span>{{ config?.offer.ogrnip }} {{ config?.offer.inn }}</span>
  <h1>{{ config?.offer.title }}</h1>
  <p v-for="(value, key) in config?.offer.common" :key="key">{{ value }}</p>
  <div v-for="(value, key) in config?.offer.paragraphs" :key="key">
    <h3>{{key}}. {{ value.title }}</h3>
    <p v-for="(point, key) in value.points" :key="key">{{ point }}</p>
  </div>
  <div class="requisites">
    <h5>{{config?.offer.requisites.title }}</h5>
    <p v-for="(value, key) in config?.offer.requisites.points" :key="key">{{ value }}</p>
  </div>
  <div class="conditions">
    <h5>{{ config?.offer.conditions }}</h5>
    <p>{{ config?.offer.ogrnip }}</p>
    <p>{{ config?.offer.inn }}</p>
  </div>
  <h2>{{ config?.offer.security.title }}</h2>
  <p v-for="(value, key) in config?.offer.security.points" :key="key">{{ value }}</p>
  <div class="requisites">
    <h5>{{config?.offer.requisites.title }}</h5>
    <p v-for="(value, key) in config?.offer.requisites.points" :key="key">{{ value }}</p>
  </div>
  <h2>{{ config?.offer.agree.title }}</h2>
  <p v-for="(value, key) in config?.offer.agree.points" :key="key">{{ value }}</p>
  <div class="requisites">
    <h5>{{config?.offer.requisites.title }}</h5>
    <p v-for="(value, key) in config?.offer.requisites.points" :key="key">{{ value }}</p>
  </div>
  <div class="conditions">
    <h5>{{ config?.offer.conditions }}</h5>
    <p>{{ config?.offer.ogrnip }}</p>
    <p>{{ config?.offer.inn }}</p>
  </div>
  <div class="tarifs">
    <h2>{{ config?.offer.tarifs.title }}</h2>
    <p v-for="(value, key) in config?.offer.tarifs.points" :key="key">{{ value }}</p>
  </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Offer',
  computed: {
    ...mapGetters([
      'config'
    ])
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/main.scss";

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

span {
  width: 100%;
  text-align: right;
  display: block;
  margin-bottom: 50px;
  font-style: italic;
}

h1, h2 {
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
}

h3 {
  margin-bottom: 20px;
}

h5 {
  margin-bottom: 20px;
}

p {
  margin-bottom: 20px;
  line-height: 1.8em;
}

.requisites {
  margin-top: 20px;
  margin-bottom: 150px;

  p {
    margin-bottom: 5px;
  }
}

.conditions {
  margin-bottom: 20px;

  h5 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 5px;
  }
}

.tarifs {
  padding-bottom: 50px;
}

</style>
