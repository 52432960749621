<template>
  <div :class="['answer', data.gender, {'active': active}]" :style="{'width': Number(90 / data.answers) + '%'}" @click="toggleActive">
    <div class="text">
      <p class="title">{{ data.data.title }}</p>
    </div>
    <img :src="require('@/assets/img/' + data.gender + '/step-' + data.step + '/' + data.data.img)" alt="">
  </div>
</template>

<script>
export default {
  name: 'answer7',
  props: {
    data: {
      type: Object
    }
  },
  data: function () {
    return {
      active: false
    }
  },
  methods: {
    toggleActive: function () {
      this.$emit('choose', { key: this.data.key })
      this.active = !this.active
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.answer {
  width: 100%;
  height: 250px;
  padding: 15px 20px;
  margin-top: 30px;
  border: 1px solid $line-color;
  box-shadow: 0 0 30px -20px black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  transition: .5s;

  @media (max-width: 480px) {
    padding: 15px;
  }

  @media (max-width: 460px) {
    padding: 15px 10px;
  }

  @media (max-width: 360px) {
    padding: 15px 3px;
  }

  @media (max-height: 530px) {
    margin-top: 10px;
  }

  @media (max-height: 510px) {
    height: 200px;
  }

  &.male:hover {
    background-color: transparentize($color-male-tertiary, .7);
  }

  &.male.active {
    background-color: transparentize($color-male-primary, .7);
  }

  &.female:hover {
    background-color: transparentize($color-female-tertiary, .7);
  }

  &.female.active {
    background-color: transparentize($color-female-primary, .7);
  }

  img {
    width: 100%;
    height: 90%;
    object-fit: contain;
  }

  .text {
    display: flex;
    flex: 0;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;

      @media (max-width: 400px) {
        font-size: .85em;
      }
    }
  }
}

</style>
